export default class Device {
  constructor() {}

  isServer(): boolean {
    return !this.isClient();
  }

  isClient(): boolean {
    return typeof window !== 'undefined';
  }

  isIPad(): boolean {
    return !!window.navigator.userAgent.match(/iPad/i);
  }

  isIPhone(): boolean {
    return !!window.navigator.userAgent.match(/iPhone/i);
  }

  isAndroid(): boolean {
    return !!window.navigator.userAgent.match(/Android/i);
  }

  isTouchDevice(): boolean {
    return 'ontouchstart' in window || window.navigator.maxTouchPoints > 0;
  }
}
