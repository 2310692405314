import styled from 'styled-components';
import {COLORS, SIZES} from '@fupa/fupa-uikit';

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: ${SIZES['16']};
  padding: ${SIZES['16']};
  margin-top: ${SIZES['16']};
  width: 100%;
  background-color: ${COLORS.white};
  max-width: ${props => props.theme.mobileContentWidth}px;

  ${props => props.theme.streamLayoutOneColBig`
      box-sizing: border-box;
      border-radius: ${SIZES['4']};
      border: 1px solid ${COLORS.lightGrey}
   `};

  ${props => props.theme.desktopLayout`
      max-width: ${props.theme.desktopContentWidth}px;
   `};
`;
