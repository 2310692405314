import {AxiosInstance, AxiosResponse} from 'axios';

export const fetchNextApiCall = <Response>(
  api: AxiosInstance,
  request: string
): Promise<AxiosResponse<Response>> | never => {
  if (!request) {
    throw new Error('Request is required for fetching API call');
  }

  return api.get(request);
};
