import {Iso8601DateString} from 'app/types/Iso8601DateString';
import DateModel from 'app/models/DateModel';

export default class Media {
  timestamp: Iso8601DateString;

  constructor(timestamp: Iso8601DateString) {
    this.timestamp = timestamp;
  }

  formatDateRelative(relativeTo?: DateModel): string {
    return DateModel.fromIso8601(this.timestamp).getRelativeTime(relativeTo);
  }
}
