import {useEffect, useState} from 'react';
import {sendEventToGA4} from 'app/helpers/gtagHelpers';
import WatchedEmbed from 'app/components/tv/utils/WatchedEmbed';

const useVideoTracking = (source, matchSlug, playlistId, videoUrl) => {
  // use state to send only one event per page impression and play interaction
  const [trackEvent, setTrackEvent] = useState(false);
  useEffect(() => {
    if (trackEvent) {
      new WatchedEmbed(playlistId).watch();
      sendEventToGA4('embed_video_start', {
        video_provider: source,
        video_url: videoUrl,
        video_playlist: playlistId,
        video_match_slug: matchSlug,
      });
    }
  }, [trackEvent]);

  return {trackEvent, setTrackEvent};
};

export {useVideoTracking};
