import {PaginatedResponse} from 'app/models/pagination/PaginatedResponse';
import {PaginatedResponseRaw} from 'app/models/pagination/PaginatedResponseRaw';
import {MediaResource} from 'app/components/mediaModule/modules/MediaResource';
import MediaFactory from 'app/components/mediaModule/modules/MediaFactory';
import MediaInterface from 'app/components/mediaModule/modules/MediaInterface';
import {captureSentryException} from 'app/services/sentryLogging';

export interface DistrictMediaResponseRaw extends PaginatedResponseRaw {
  data: MediaResource[];
}

export default class DistrictMediaResponse extends PaginatedResponse {
  data: MediaInterface[];

  constructor(data: DistrictMediaResponseRaw) {
    super(data);
    this.data = data.data
      .map(mediaData => {
        try {
          return MediaFactory.create(mediaData);
        } catch (e) {
          captureSentryException(e);
          return null;
        }
      })
      .filter((element): element is MediaInterface => element !== null);
  }
}
