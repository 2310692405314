import React, {useEffect, useRef} from 'react';
import {useVideoTracking} from 'app/components/videoEmbeeds/useVideoTracking';

const playerScriptId = 'staige-script';
const playerStylesId = 'staige-styles';

const initPlayerCss = () => {
  if (!document.getElementById(playerStylesId)) {
    const link = document.createElement('link');
    link.href = 'https://storage.googleapis.com/aisw-assets/player/16.0.12/aisw-player.css';
    link.rel = 'stylesheet';
    link.id = playerStylesId;
    document.head.appendChild(link);
  }
};

const initPlayerScript = () => {
  if (!document.getElementById(playerScriptId)) {
    const script = document.createElement('script');
    script.src = 'https://storage.googleapis.com/aisw-assets/player/16.0.12/aisw-player.js';
    script.type = 'text/javascript';
    script.id = playerScriptId;
    document.head.appendChild(script);
  }
};

const VideoEmbedStaige = ({isHydrationFinished, id, source, matchSlug, playlistId, videoUrl}) => {
  const {setTrackEvent} = useVideoTracking(source, matchSlug, playlistId, videoUrl);
  const staigeRef = useRef(null);

  useEffect(() => {
    const staigePlayer = staigeRef.current;
    const handlePlay = event => {
      event.detail.play.subscribe(() => setTrackEvent(true));
    };
    if (staigePlayer) {
      staigePlayer.addEventListener('hello', handlePlay);
    }
    return () => {
      if (staigePlayer) {
        staigePlayer.removeEventListener('hello', handlePlay);
      }
    };
  }, [staigeRef]);

  useEffect(() => {
    if (isHydrationFinished) {
      initPlayerCss();
      initPlayerScript();
    }
  }, [isHydrationFinished]);

  return <aisw-player ref={staigeRef} playNow='highlight' autoplay={false} gameId={id} />;
};

export {VideoEmbedStaige};
