import React, {useEffect, useLayoutEffect, useRef} from 'react';
import {useVideoTracking} from 'app/components/videoEmbeeds/useVideoTracking';

const playerScriptId = 'oneFootball-script';

const initPlayerScript = () => {
  if (!document.getElementById(playerScriptId)) {
    const script = document.createElement('script');
    script.src = 'https://embed.dugout.com/v3.1/fupa.js';
    script.type = 'text/javascript';
    script.id = playerScriptId;
    document.head.appendChild(script);
  }
};

export const VideoEmbedOneFootball = ({isHydrationFinished, id, source, matchSlug, playlistId}) => {
  const {setTrackEvent} = useVideoTracking(source, matchSlug, playlistId);
  const oneFootballRef = useRef(null);

  useLayoutEffect(() => {
    if (oneFootballRef?.current) {
      oneFootballRef.current.addEventListener('play', () => setTrackEvent(true));
    }
    return () => {
      if (oneFootballRef?.current) {
        oneFootballRef.current.removeEventListener('play', () => setTrackEvent(true));
      }
    };
  }, [oneFootballRef]);

  useEffect(() => {
    if (isHydrationFinished) {
      initPlayerScript();
    }
  }, [isHydrationFinished]);

  return <div ref={oneFootballRef} className={`dugout-video dugout-embed-${id}`}></div>;
};
