import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: ${props => (props.width ? `${props.width}px` : 'auto')};
`;

const VideoEmbedSporttotal = ({id}) => {
  const ref = useRef(null);
  const [embedWidth, setEmbedWidth] = useState(ref.current?.scrollWidth);

  useEffect(() => {
    window.addEventListener('resize', resizeEmbed);
    return () => window.removeEventListener('resize', resizeEmbed);
  });

  useEffect(() => {
    resizeEmbed();
  }, [ref]);

  const resizeEmbed = () => {
    setEmbedWidth((ref.current.scrollWidth / 16) * 9);
  };

  const src = 'https://www.sporttotal.tv/' + id + '?embed';
  return (
    <React.Fragment key={id}>
      <Wrapper ref={ref} width={embedWidth}>
        <iframe className='sw-iframe' src={src} width='100%' height='100%' frameBorder='0' allowFullScreen />
      </Wrapper>
    </React.Fragment>
  );
};

export {VideoEmbedSporttotal};
