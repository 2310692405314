import {LinksInterface} from 'app/models/pagination/LinksInterface';
import {MetaInterface} from 'app/models/pagination/MetaInterface';
import {PaginatedResponseRaw} from 'app/models/pagination/PaginatedResponseRaw';
import {PaginatedResponseInterface} from 'app/models/pagination/PaginatedResponseInterface';

const DEFAULT_MAX_NUMBER_OF_INDEXED_PAGES = 10;

export abstract class PaginatedResponse implements PaginatedResponseInterface {
  abstract data: any[];
  links: LinksInterface;
  meta: MetaInterface;
  readonly MAX_NUMBER_OF_INDEXED_PAGES: number;

  protected constructor(
    data: PaginatedResponseRaw,
    MAX_NUMBER_OF_INDEXED_PAGES: number = DEFAULT_MAX_NUMBER_OF_INDEXED_PAGES
  ) {
    this.links = data.links;
    this.meta = data.meta;
    this.MAX_NUMBER_OF_INDEXED_PAGES = MAX_NUMBER_OF_INDEXED_PAGES;
  }

  getNextLinkParams(): string | null {
    if (!this.links.next) {
      return null;
    }
    const nextPage = this.meta.currentPage + 1;
    // We don't want the crawler to see (and index) more than a specific number of pages (default is 10) for paginated content
    if (nextPage > this.MAX_NUMBER_OF_INDEXED_PAGES) {
      return null;
    }
    return `?page=${nextPage}`;
  }

  getPrevLinkParams(): string | null {
    if (!this.links.prev) {
      return null;
    }
    return `?page=${this.meta.currentPage - 1}`;
  }
}
