import {api} from 'app/services/api';
import {updateHttpError} from 'app/components/error/ErrorHandlerActions';
import {AxiosError, AxiosResponse} from 'axios';
import DistrictMediaResponse, {
  DistrictMediaResponseRaw,
} from 'app/components/mediaModule/modules/DistrictMediaResponse';
import {updateHistory} from 'app/actions/historyActions';
import {fetchNextApiCall} from 'app/services/fetchNextApiCall';
import MediaInterface from 'app/components/mediaModule/modules/MediaInterface';
import FuPaApiError from 'app/models/error/FuPaApiError';
import getParamsStringFromOptions, {QueryOptions} from 'app/queries/common/getParamStringFromOptions';

const fetchDistrictMedia = async (slug: string, options?: QueryOptions): Promise<DistrictMediaResponseRaw> => {
  let url = `/districts/${slug}/media${options ? `?${getParamsStringFromOptions(options)}` : ''}`;
  const response: AxiosResponse<DistrictMediaResponseRaw> = await api.get(url);
  return response.data;
};

const mediaOptions: QueryOptions = {maxDays: 30, limit: 20};

const fetchMediaList = async (slug: string): Promise<MediaInterface[]> => {
  try {
    const response = await fetchDistrictMedia(slug, mediaOptions);
    return new DistrictMediaResponse(response).data;
  } catch (error) {
    if (error instanceof AxiosError) {
      const fupaApiError = FuPaApiError.fromAxiosError(error);
      fupaApiError.logClientError('Media Module');
    }
    throw error;
  }
};

const fetchMediaListSSR = (slug: string) => {
  return async function (dispatch: any) {
    try {
      const response = await fetchDistrictMedia(slug, mediaOptions);
      dispatch(
        updateHistory('undefined', {
          store: 'DistrictMainPage',
          data: {media: response.data},
        })
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        const fupaApiError = FuPaApiError.fromAxiosError(error);
        if (fupaApiError.isTimeout()) {
          fupaApiError.logError();
          fupaApiError.status = 500;
        }
        dispatch(
          updateHistory('undefined', {
            store: 'DistrictMainPage',
            data: {media: fupaApiError.toPlain()},
          })
        );
      } else {
        throw error;
      }
    }
  };
};

const fetchAllMediaContentsList = async (
  slug: string,
  url: string | null,
  dispatch: any
): Promise<DistrictMediaResponse> => {
  try {
    const response = url
      ? (await fetchNextApiCall<DistrictMediaResponseRaw>(api, url)).data
      : await fetchDistrictMedia(slug);
    return new DistrictMediaResponse(response);
  } catch (error) {
    if (error instanceof AxiosError) {
      const fupaApiError = FuPaApiError.fromAxiosError(error);
      fupaApiError.logClientError('Media-Stream');
      if (!url) {
        dispatch(updateHttpError(fupaApiError.toPlain()));
      }
    }
    throw error;
  }
};

const fetchAllMediaContentsListSSR = (slug: string) => {
  return async function (dispatch: any) {
    try {
      const response = await fetchDistrictMedia(slug);
      dispatch(
        updateHistory('undefined', {
          store: 'DistrictAllContentsPage',
          data: response,
        })
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        const fupaApiError = FuPaApiError.fromAxiosError(error);
        dispatch(updateHttpError(fupaApiError.toPlain()));
      } else {
        throw error;
      }
    }
  };
};

export {fetchMediaList, fetchMediaListSSR, fetchAllMediaContentsList, fetchAllMediaContentsListSSR};
