import LocalStorageManager from 'app/services/web-storage/LocalStorageManager';

const TWO_DAYS_IN_MINUTES = 60 * 24 * 2;

export default class WatchedEmbed {
  private readonly storeKey: string;
  private readonly localStorageManager: LocalStorageManager;

  constructor(embedId: number) {
    this.storeKey = `embed-${embedId}`;
    this.localStorageManager = LocalStorageManager.getInstance();
  }

  watch() {
    const watchedEmbeds = this.localStorageManager.getItem(this.storeKey);
    if (watchedEmbeds) {
      return;
    }
    this.localStorageManager.setItem(this.storeKey, true, TWO_DAYS_IN_MINUTES);
  }

  isWatched() {
    return !!this.localStorageManager.getItem(this.storeKey);
  }
}
