export interface QueryOptions {
  page?: number;
  maxDays?: number;
  limit?: number;
  offset?: number;
}

export default function getParamsStringFromOptions(options: QueryOptions): string {
  return Object.keys(options)
    .map(option => `${option}=${options[option]}`)
    .join('&');
}
