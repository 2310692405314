import {MediaResource} from 'app/components/mediaModule/modules/MediaResource';
import MediaInterface from 'app/components/mediaModule/modules/MediaInterface';
import {MediaType} from 'app/components/mediaModule/modules/MediaType';
import MediaGallery from 'app/components/mediaModule/modules/MediaGallery';
import MediaPlaylist from 'app/components/mediaModule/modules/MediaPlaylist';
import MediaEmbed from 'app/components/mediaModule/modules/MediaEmbed';

export default class MediaFactory {
  static create(data: MediaResource): MediaInterface {
    switch (data.mediaType) {
      case MediaType.Gallery:
        return new MediaGallery(data);
      case MediaType.Playlist:
        return new MediaPlaylist(data);
      case MediaType.Embed:
        return new MediaEmbed(data);
      default:
        throw new Error(`Unknown media type: ${data.mediaType}`);
    }
  }
}
