import MediaInterface from 'app/components/mediaModule/modules/MediaInterface';
import {ImageInterface} from 'app/types/ImageInterface';
import {Iso8601DateString} from 'app/types/Iso8601DateString';
import {MediaType} from 'app/components/mediaModule/modules/MediaType';
import {MediaResource} from 'app/components/mediaModule/modules/MediaResource';
import DateModel from 'app/models/DateModel';
import Media from 'app/components/mediaModule/modules/Media';

export default class MediaPlaylist implements MediaInterface {
  readonly id: number;
  readonly slug: string;
  readonly title: string;
  readonly image: ImageInterface;
  readonly timestamp: Iso8601DateString;
  readonly competitionName: string;
  readonly mediaType: MediaType.Playlist;
  readonly typeData: null;
  readonly media: Media;

  constructor(data: MediaResource) {
    this.id = data.id;
    this.slug = data.slug;
    this.title = data.title;
    this.image = data.image;
    this.timestamp = data.timestamp;
    this.competitionName = data.competitionName;
    this.mediaType = MediaType.Playlist;
    this.typeData = null;
    this.media = new Media(data.timestamp);
  }

  getLink(): string {
    return `/tv/match/${this.slug}`;
  }

  formatDateRelative(relativeTo?: DateModel): string {
    return this.media.formatDateRelative(relativeTo);
  }
}
