import MediaInterface from 'app/components/mediaModule/modules/MediaInterface';
import {ImageInterface} from 'app/types/ImageInterface';
import {Iso8601DateString} from 'app/types/Iso8601DateString';
import {MediaType} from 'app/components/mediaModule/modules/MediaType';
import {MediaResource} from 'app/components/mediaModule/modules/MediaResource';
import DateModel from 'app/models/DateModel';
import Media from 'app/components/mediaModule/modules/Media';
import EmbeddedVideoDataInterface from 'app/components/videoEmbed/types/EmbeddedVideoDataInterface';

export default class MediaEmbed implements MediaInterface {
  readonly id: number;
  readonly slug: string;
  readonly title: string;
  readonly image: ImageInterface;
  readonly timestamp: Iso8601DateString;
  readonly competitionName: string;
  readonly mediaType: MediaType.Embed;
  readonly typeData: EmbeddedVideoDataInterface;
  readonly media: Media;

  constructor(data: MediaResource) {
    this.id = data.id;
    this.slug = data.slug;
    this.title = data.title;
    this.image = data.image;
    this.timestamp = data.timestamp;
    this.competitionName = data.competitionName;
    this.mediaType = MediaType.Embed;
    if (!data.typeData) {
      throw new Error('MediaEmbed must have typeData');
    }
    this.typeData = data.typeData;
    this.media = new Media(data.timestamp);
  }

  getLink(): string {
    return '';
  }

  formatDateRelative(relativeTo?: DateModel): string {
    return this.media.formatDateRelative(relativeTo);
  }
}
